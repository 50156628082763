var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListContainerWidget',{attrs:{"id":(_vm.mediaType + "s"),"title":_vm.widgetName,"app-id":_vm.app.id,"view-more-location":_vm.getLocation((_vm.mediaType + "s")),"size":_vm.addOnSize,"stretch":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{ref:"target"}),_c('ViewAllButton',{attrs:{"item-type":(_vm.mediaType + "s")}})]},proxy:true}])},[[(_vm.isLoading)?_c('b-spinner',{staticClass:"mx-auto mt-3 d-block",attrs:{"type":"grow","small":""}}):(_vm.items && _vm.items.length > 0)?_c('div',[_c('div',{ref:"container",staticClass:"x-scroll-container w-100",on:{"scroll":_vm.onScroll}},[_vm._l((_vm.items),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.canList(item)),expression:"canList(item)"}],key:item.key,staticClass:"x-scroll-element mx-1 p-0",class:_vm.mediaType === 'photo' ? 'mb-3' : 'mb-1'},[(_vm.mediaType === 'photo')?_c('b-link',{staticClass:"media-row text-dark",attrs:{"to":{
              name: (_vm.mediaType + "-details"),
              params: {
                itemKey: item.key,
                communityId: _vm.$route.params.communityId,
              },
            }}},[_c('b-card',{staticClass:"gallery-image mb-0"},[_c('FluidSafeImg',{attrs:{"src":_vm.getImageSrc(item.url)||_vm.getImageSrc(item.thumbnailURL),"placeholder":_vm.placeholder,"alt":_vm.translateTranslationTable(_vm.currentLocale, item.name),"fluid":""}}),_c('div',{staticClass:"gallery-image__overlay"},[_c('div',{staticClass:"gallery-image__text"},[_c('div',[(item.createdBy && _vm.canShowCollaborator)?_c('UserAvatar',{staticClass:"mr-1 float-right",attrs:{"user":item.createdBy,"size":"32"}}):_vm._e()],1),_c('div',{staticClass:"d-block media-text"},[_c('p',{staticClass:"title m-0",class:_vm.hasLongTitle(_vm.title(item.name))?'html-text-ellipsis-2 text-ellipsis-lines-2':'html-text-ellipsis-1 text-ellipsis-lines-1'},[_vm._v(" "+_vm._s(_vm.title(item.name))+" ")]),(item.createdBy && _vm.canShowCollaborator)?_c('p',{staticClass:"subtitle m-0 text-muted",class:_vm.hasLongTitle(_vm.title(item.name))?'html-text-ellipsis-1':'html-text-ellipsis-2'},[_vm._v(" "+_vm._s(item.createdBy.name)+" "+_vm._s(item.createdBy.surname)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex text-muted"},[_c('span',{staticClass:"mr-1"},[_c('feather-icon',{staticClass:"mr-25",class:item.likedByMember
                              ? 'profile-likes'
                              : 'profile-icon',attrs:{"role":"button","size":"14","icon":"HeartIcon"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleLike(item)}}}),_vm._v(" "+_vm._s(item.totalLikes || 0)+" ")],1),_c('span',[_c('feather-icon',{staticClass:"mr-25",attrs:{"role":"button","size":"14","icon":"MessageSquareIcon"}}),_vm._v(" "+_vm._s(item.commentCount || 0)+" ")],1)])])])])],1)],1):_vm._e(),(_vm.mediaType !== 'photo')?_c('b-link',{staticClass:"media-row text-dark",attrs:{"to":{
              name: (_vm.mediaType + "-details"),
              params: {
                itemKey: item.key,
                communityId: _vm.$route.params.communityId,
              },
            }}},[_c('b-media',{staticClass:"media-row flex-column"},[(_vm.mediaType === 'audio')?_c('div',{staticClass:"p-0 card-standard audio"},[_c('SafeImg',{attrs:{"src":_vm.getImageSrc(item.thumbnailURL),"placeholder":_vm.placeholderItems,"alt":_vm.translateTranslationTable(_vm.currentLocale, item.name),"retry":5000}})],1):_c('div',{staticClass:"p-0 card-standard video"},[_c('SafeImg',{staticClass:"thumbnail",attrs:{"src":_vm.getImageSrc(item.thumbnailURL),"placeholder":_vm.placeholderItems,"alt":_vm.translateTranslationTable(_vm.currentLocale, item.name),"retry":5000}})],1),_c('div',{class:_vm.mediaType !== 'audio' ? 'video d-flex' : 'audio d-flex'},[_c('div',[(item.createdBy && _vm.canShowCollaborator)?_c('UserAvatar',{staticClass:"mr-1 float-right",attrs:{"user":item.createdBy,"size":"32"}}):_vm._e()],1),_c('div',{staticClass:"d-block media-text w-100"},[_c('p',{staticClass:"title m-0 html-text-ellipsis-2"},[_vm._v(" "+_vm._s(_vm.title(item.name))+" ")]),(item.createdBy && _vm.canShowCollaborator)?_c('p',{staticClass:"subtitle m-0 text-muted html-text-ellipsis-1"},[_vm._v(" "+_vm._s(item.createdBy.name)+" "+_vm._s(item.createdBy.surname)+" ")]):_vm._e(),_c('div',{class:_vm.mediaType !== 'audio' ?'d-flex text-muted justify-content-between w-100 pr-50':'text-muted d-block'},[_c('p',{staticClass:"subtitle m-0 text-muted small html-text-ellipsis-1"},[_vm._v(" "+_vm._s(_vm.date(item))+" ")]),_c('div',{staticClass:"d-flex "},[(item.isCompletable)?_c('span',{staticClass:"mr-1"},[_c('feather-icon',{staticClass:"profile-icon mr-25",class:item.completedByMember ? 'item-completed' : '',attrs:{"size":"16","icon":"CheckCircleIcon"}})],1):_vm._e(),_c('span',{staticClass:"mr-1"},[_c('feather-icon',{staticClass:"mr-25",class:item.likedByMember
                              ? 'profile-likes'
                              : 'profile-icon',attrs:{"role":"button","size":"14","icon":"HeartIcon"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleLike(item)}}}),_vm._v(" "+_vm._s(item.totalLikes || 0)+" ")],1),_c('span',[_c('feather-icon',{staticClass:"mr-25",attrs:{"role":"button","size":"14","icon":"MessageSquareIcon"}}),_vm._v(" "+_vm._s(item.commentCount || 0)+" ")],1)])])])])])],1):_vm._e()],1)}),(_vm.mediaType === 'photo')?[_c('PhotosCarouselModal',{attrs:{"visible":_vm.isCarouselVisible,"value":_vm.currentCarouselIndex,"photos":_vm.items},on:{"update:visible":function($event){_vm.isCarouselVisible=$event},"update:value":function($event){_vm.currentCarouselIndex=$event}}})]:_vm._e(),_c('button',{staticClass:"link-previous btn btn-icon btn-primary btn-previous",on:{"click":function($event){return _vm.scrollOnePage(-1)}}},[_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}})],1),_c('button',{staticClass:"link-next btn btn-icon btn-primary btn-next",class:{ 'btn-next--loading': _vm.isLoadingNextPage },on:{"click":function($event){return _vm.scrollOnePage(+1)}}},[_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}})],1)],2)]):_c('b-row',{staticClass:"horizontal-placeholder"},[_c('b-col',{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":_vm.placeholder}})]),_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t("available.message", { itemName: _vm.mediaType }))+" ")])])],1),(_vm.isAddingMedia)?[_c('MediaUploadModal',{attrs:{"media-type":_vm.mediaType},on:{"media-created":_vm.mediaCreated},model:{value:(_vm.isAddingMedia),callback:function ($$v) {_vm.isAddingMedia=$$v},expression:"isAddingMedia"}})]:_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }