<template>
  <ListContainerWidget
    :id="`${mediaType}s`"
    :title="widgetName"
    :app-id="app.id"
    :view-more-location="getLocation(`${mediaType}s`)"
    :size="addOnSize"
    stretch
  >
    <template #actions>
      <!-- CUIDADO!!!!! NO BORRAR  -->
      <div ref="target" />
      <!-- <div ref="target" style="width: 5px; height: 5px; background-color: black;" /> -->

      <!-- Panel actions -->
      <ViewAllButton :item-type="`${mediaType}s`" /> 
      
    </template>

    <template>
      <b-spinner v-if="isLoading" type="grow" small class="mx-auto mt-3 d-block" />

      <div v-else-if="items && items.length > 0">
        <div
          ref="container"
          class="x-scroll-container w-100"
          @scroll="onScroll"
        >
          <div
            v-for="item in items"
            v-show="canList(item)"
            :key="item.key"
            class="x-scroll-element mx-1 p-0"
            :class="mediaType === 'photo' ? 'mb-3' : 'mb-1'"
          >
            <!-- Photo Media -->
            <b-link
              v-if="mediaType === 'photo'"
              class="media-row text-dark"
              :to="{
                name: `${mediaType}-details`,
                params: {
                  itemKey: item.key,
                  communityId: $route.params.communityId,
                },
              }"
            >
              <b-card class="gallery-image mb-0">
                <FluidSafeImg
                  :src="getImageSrc(item.url)||getImageSrc(item.thumbnailURL)"
                  :placeholder="placeholder"
                  :alt="translateTranslationTable(currentLocale, item.name)"
                  fluid
                />
                <div class="gallery-image__overlay">
                  <div class="gallery-image__text">
                    <div>
                      <UserAvatar
                        v-if="item.createdBy && canShowCollaborator"
                        :user="item.createdBy"
                        size="32"
                        class="mr-1 float-right"
                      />
                    </div>
                    <div class="d-block media-text">
                      <p class="title m-0" :class="hasLongTitle(title(item.name))?'html-text-ellipsis-2 text-ellipsis-lines-2':'html-text-ellipsis-1 text-ellipsis-lines-1'">
                        {{
                          title(item.name)
                        }}
                      </p>
                      <p v-if="item.createdBy && canShowCollaborator" class="subtitle m-0 text-muted" :class="hasLongTitle(title(item.name))?'html-text-ellipsis-1':'html-text-ellipsis-2'">
                        {{ item.createdBy.name }} {{ item.createdBy.surname }}
                      </p>
                      <div class="d-flex text-muted">
                        <span class="mr-1">
                          <feather-icon
                            role="button"
                            size="14"
                            :class="
                              item.likedByMember
                                ? 'profile-likes'
                                : 'profile-icon'
                            "
                            icon="HeartIcon"
                            class="mr-25"
                            @click.stop.prevent="toggleLike(item)"
                            
                          />
                          {{ item.totalLikes || 0 }}
                        </span>
                        <span>
                          <feather-icon
                            role="button"
                            size="14"
                            icon="MessageSquareIcon"
                            class="mr-25"
                          />
                          {{ item.commentCount || 0 }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-link>

            <!-- Other Media -->
            <b-link
              v-if="mediaType !== 'photo'"
              :to="{
                name: `${mediaType}-details`,
                params: {
                  itemKey: item.key,
                  communityId: $route.params.communityId,
                },
              }"
              class="media-row text-dark"
            >
              <b-media class="media-row flex-column">
                <!-- Audio Media -->
                <div
                  v-if="mediaType === 'audio'"
                  class="p-0 card-standard audio"
                >
                  <SafeImg
                    :src="getImageSrc(item.thumbnailURL)"
                    :placeholder="placeholderItems"
                    :alt="translateTranslationTable(currentLocale, item.name)"
                    :retry="5000"
                  />
                </div>
                <!-- Other Audio Media -->
                <div v-else class="p-0 card-standard video">
                  <SafeImg
                    class="thumbnail"
                    :src="getImageSrc(item.thumbnailURL)"
                    :placeholder="placeholderItems"
                    :alt="translateTranslationTable(currentLocale, item.name)"
                    :retry="5000"
                  />
                </div>

                <div :class="mediaType !== 'audio' ? 'video d-flex' : 'audio d-flex'">
                  <div>
                    <UserAvatar
                      v-if="item.createdBy && canShowCollaborator"
                      :user="item.createdBy"
                      size="32"
                      class="mr-1 float-right"
                    />
                  </div>
                  <div class="d-block media-text w-100">
                    <p class="title m-0 html-text-ellipsis-2">
                      {{ title(item.name) }}
                    </p>
                    <p
                      v-if="item.createdBy && canShowCollaborator"
                      class="subtitle m-0 text-muted html-text-ellipsis-1"
                    >
                      {{ item.createdBy.name }} {{ item.createdBy.surname }}
                    </p>
                    <div :class="mediaType !== 'audio' ?'d-flex text-muted justify-content-between w-100 pr-50':'text-muted d-block'">
                    <p
                      class="subtitle m-0 text-muted small html-text-ellipsis-1"
                    >
                      {{ date(item) }}
                    </p>
                      <div class="d-flex ">
                        <span v-if="item.isCompletable" class="mr-1">
                          <feather-icon
                            size="16"
                            :class="item.completedByMember ? 'item-completed' : ''"
                            class="profile-icon mr-25"
                            icon="CheckCircleIcon"
                          />
                        </span>
                        <span class="mr-1">
                          <feather-icon
                            role="button"
                            size="14"
                            :class="
                              item.likedByMember
                                ? 'profile-likes'
                                : 'profile-icon'
                            "
                            icon="HeartIcon"
                            class="mr-25"
                            @click.stop.prevent="toggleLike(item)"
                            
                          />
                          {{ item.totalLikes || 0 }}
                        </span>
                        <span>
                          <feather-icon
                            role="button"
                            size="14"
                            icon="MessageSquareIcon"
                            class="mr-25"
                          />
                          {{ item.commentCount || 0 }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </b-media>
            </b-link>
          </div>
          
          <!-- Modal Gallery Photos -->
          <template v-if="mediaType === 'photo'">
            <PhotosCarouselModal
              :visible.sync="isCarouselVisible"
              :value.sync="currentCarouselIndex"
              :photos="items"
            />
          </template>

          <!-- Buttons prev and next -->
          <button
            class="link-previous btn btn-icon btn-primary btn-previous"
            @click="scrollOnePage(-1)"
          >
            <b-spinner small class="spinner" />
          </button>
          <button
            class="link-next btn btn-icon btn-primary btn-next"
            :class="{ 'btn-next--loading': isLoadingNextPage }"
            @click="scrollOnePage(+1)"
          >
            <b-spinner small class="spinner" />
          </button>
        </div>
      </div>
      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="placeholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: mediaType }) }}
          </p>
        </b-col>
      </b-row>
      <template v-if="isAddingMedia">
        <MediaUploadModal
          v-model="isAddingMedia"
          :media-type="mediaType"
          @media-created="mediaCreated"
        />
      </template>
    </template>
  </ListContainerWidget>
</template>

<script>
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import {
  PlaceholdersImage,
  PlaceholdersImageItems,
} from "@/views/apps/media/constants/MediaType";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { getImageResource } from "@/@core/utils/image-utils";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import { checkPermissions } from "@/@core/utils/roles-utils";
import vSelect from "vue-select";

import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core'

export default {
  name: "MediaListWidget",

  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },

  components: {
    ListContainerWidget,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
    // WidgetActions: () => import("@/@core/components/widget-actions/WidgetActions.vue" /* webpackChunkName: "WidgetActions" */),
    vSelect,
    SafeImg: () => import('@core/components/safe-img/SafeImg.vue' /* webpackChunkName: "SafeImg" */),
    MediaUploadModal: () => import('@/views/apps/media/components/modal/MediaUploadModal.vue' /* webpackChunkName: "MediaUploadModal" */),
    UserAvatar: () => import('@core/components/user/UserAvatar.vue' /* webpackChunkName: "UserAvatar" */),
    FluidSafeImg: () => import('@/@core/components/safe-img/FluidSafeImg.vue' /* webpackChunkName: "FluidSafeImg" */),
    PhotosCarouselModal: () => import('../../photos/components/PhotosCarouselModal.vue' /* webpackChunkName: "PhotosCarouselModal" */),
  },

  mixins: [WidgetLayoutMixin],

  props: {
    mediaType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isAddingMedia: false,
      lastLoadedPage: 1,
      isLoading: false,
      searchInput: "",
      isLoadingNextPage: false,
      isCarouselVisible: false,
      currentCarouselIndex: 0,
      results: [],
    };
  },

  computed: {
    isLandingSkin() {
      return this.$store.state.appConfig.layout.skin === "landing";
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData.unpaginated;

      return Array.from(new Set(itemsToIterate));
    },
    itemsData() {
      if (this.$store.getters.media[this.mediaType]) {
        return this.$store.getters.media[this.mediaType];
      }
      return [];
    },
    total() {
      return this.itemsData.meta.total;
    },
    canShowCollaborator() {
      return this.widget?.customization?.collaboratorDisplay;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    placeholder() {
      return PlaceholdersImage(this.mediaType);
    },
    placeholderItems() {
      return PlaceholdersImageItems(this.mediaType);
    },
    canPostMedia() {
      return this.$store.getters.currentCollective.isStaff;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },

    canAdd() {
      return (
        this.canPostMedia ||
        checkPermissions(
          "create",
          `${this.mediaType}`,
          this.loggedMemberRoles,
          this.collective
        )
      );
    },

    widget() {
      const app = Object.values(
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
      ).filter((item) => {
        if (item.id === this.app.id) {
          return item.name;
        }
      });
      return app[0];
    },
  },

  watch: {
    targetIsVisible (newValue) {
      // console.log("++++++++++++ --> WATCH!!! MediaListWidget.vue (targetIsVisible): ", newValue)
      if (newValue && !this.items.length) {
        // console.log("2 --> WATCH!!! MediaListWidget.vue (targetIsVisible): ", newValue)
        this.loadAllData()
      }
    }
  },

  // async created() {
  //   this.isLoading = true;
  //   await this.fetchData(true);
  //   await this.fetchClassifiers();
  //   this.isLoading = false;
  // },

  methods: {
    async loadAllData () {
      this.isLoading = true;
      await this.fetchData(true);
      this.isLoading = false;
    },

    title(name){
      const translateName = translateTranslationTable(this.currentLocale, name)
      return translateName?.replaceAll("_", " ")
    },
    hasLongTitle(title){
     return title?.length > 26
    },
    canList(item) {
      return (
        this.loggedUser.key === item.createdBy?.key ||
        this.canPostMedia ||
        checkPermissions(
          "index",
          `${this.mediaType}`,
          this.loggedMemberRoles,
          this.collective
        )
      );
    },
    /*     handlePhotoCardClick(_photo, index) {
      this.currentCarouselIndex = index;
      this.isCarouselVisible = true;
    }, */

    getAvatar(url) {
      return getImageResource(url);
    },
    getImage(src) {
      const bgImage = this.getImageSrc(src);
      return { background: `url("${bgImage}") no-repeat` };
    },
    async mediaCreated() {
      await this.fetchData(true);
    },
    async fetchData(force = false) {
      this.isLoading = true;
      await this.$store.dispatch("getItems", {
        itemType: "media",
        storedKey: this.mediaType,
        page: this.lastLoadedPage,
        forceAPICall: force,
        requestConfig: {
          count: 20,
          morphType: this.mediaType,
          orderByDate: -1,
        },
      });
      this.isLoading = false;
    },
    date(item) {
      return this.$moment(item.createdAt).fromNow();
    },
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        await this.fetchData();
      }
    },
    translateTranslationTable,
    getImageSrc(url) {
      return getImageResource(url);
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container.scrollLeft;
      const toScroll = this.$refs.container.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
      });
    },
    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd =
        event.srcElement.scrollWidth -
        event.srcElement.scrollLeft -
        event.srcElement.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Search methods
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.itemsData.unpaginated.filter((item) =>
        typeof item.name === "string"
          ? item.name.toLowerCase().includes(searchTerm)
          : this.translateTranslationTable(this.currentLocale, item.name)
              .toLowerCase()
              .includes(searchTerm)
      );
    },
    async toggleLike(item) {
      await this.$store.dispatch('toggleLike', {
        itemType: 'media',
        morphType: this.mediaType,
        key: item?.key,
        storedKey: this.mediaType,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
@import "~@core/scss/base/components/gallery";

.large-thumbnail {
  margin-left: 10px;
  width: 220px;
  border-radius: 1.2rem;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 0px 0px 15px -5px;
}

.thumbnail {
  border-radius: 1.2rem;
  width: 100%;
  object-fit: cover;
  min-height: 140px;
}

.title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  white-space: normal;
}
.subtitle {
  margin-bottom: 0.5rem;
}
.card-body {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 1.2rem;
}

.card-standard {
  border-style: none;
  height: auto;
  max-height: 180.44px;
  min-height: 180.44px;
  border-radius: 1.2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  img {
    min-height: 180.44px;
    object-fit: cover;
    height: 180.44px;
    min-height: 180.44px;
  }
}
.video {
  width: 320px;
  img {
    min-height: 180.44px;
    min-width: 320px;
    object-fit: cover;
  }
}
.audio {
  width: 180.44px;
  img {
    min-width: 180.44px;
    object-fit: cover;
  }
}
.media-text {
  height: 84px;
}

// using 'down' media query to override bootstrap's b-media
@include media-breakpoint-down(sm) {
  .media-row {
    align-items: center;
    &::v-deep {
      .media-aside {
        align-self: center !important;
        margin-right: 0;
        img {
          margin-right: 0 !important;
        }
      }
    }
  }
}
.item-completed {
  color: green !important;
  font-weight: 700;
  background-color: #b4f5b4;
}
</style>
